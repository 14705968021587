/*
 * @Author: qiuyu
 * @Date: 2021-05-18 09:13:25
 * @LastEditTime: 2021-05-18 17:19:57
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \emwappopads\config\wap.js
 */

/**
 * 前端接口配置抽取
 */

 module.exports = {
    dev: {
      ad_api: function(){ //期货接口
        return 'https://emtest.securities.eastmoney.com:8156/'
      }
    },
    test: {
      ad_api: function(){
        return 'https://emtest.securities.eastmoney.com:8156/'
      }
    },
    production: {
      ad_api: function(){
        return 'https://emdcadvertise-wap.eastmoney.com/'
      }
    },
    getParam: function(name){
      var urlpara = location.search;
      var par = {};
      if (urlpara != "") {
        urlpara = urlpara.substring(1, urlpara.length);
        var para = urlpara.split("&");
        var parname;
        var parvalue;
        for (var i = 0; i < para.length; i++) {
          parname = para[i].substring(0, para[i].indexOf("="));
          parvalue = para[i].substring(para[i].indexOf("=") + 1, para[i].length);
          par[parname] = parvalue;
        }
      }
      if(typeof (par[name]) != "undefined"){
        return par[name];
      }
      else{
        return null;
      }
    },
    getPath: function (name) {
      if (this.getParam('env')) {
        return this[this.getParam('env')][name]()
      }
      return this.production[name]()
    }
  }