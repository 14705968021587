/*
 * @Author: your name
 * @Date: 2021-05-18 09:12:23
 * @LastEditTime: 2021-06-08 11:01:55
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \emwappopads\src\modules\cookie\index.js
 */
var Cookie = {
    getCookie:function (name) {
        var strCookie = document.cookie;
        var arrCookie = strCookie.split("; ");
        for (var i = 0; i < arrCookie.length; i++) {
            var arr = arrCookie[i].split("=");
            if (name == arr[0]) {
                return arr[1];
            }
        }
        return "";
    },
    setCookie:function(c_name, value, expiredays) {
        var exdate = new Date();                   
        exdate.setDate(exdate.getDate() + expiredays);        
        document.cookie = c_name + "=" + escape(value) + ";expires=" + exdate.toGMTString() + ";domain=eastmoney.com;path=/";
    } 
}

module.exports = Cookie;